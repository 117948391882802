import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Header from '../components/Header'
import Tickets from 'gatsby-theme-psg/src/components/Tickets/Eventix'
import Lead from '../components/Lead';

export const TicketsPageTemplate = ({ title, content }) => {
    return (
        <React.Fragment>
            <Header>
                <Lead title={title} content={content}/>
            </Header>
            <div className={"content"}>
                <div className="container mb-5 pb-5">
                    <Tickets />
                </div>
            </div>
        </React.Fragment>
    )
}

const TicketsPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
            <TicketsPageTemplate title={post.frontmatter.title} content={post.html} />
        </Layout>
    )
}

export default TicketsPage

export const pageQuery = graphql`
    query TicketsPage {
        markdownRemark(frontmatter: { templateKey: { eq: "tickets-page" } }) {
            html
            frontmatter {
                title
                description
            }
        }
    }
`
