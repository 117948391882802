import React from 'react'
import HTMLContent from 'gatsby-theme-psg/src/components/Content'

export default ({ title, content }) => {
  return (
    <div className="header-content">
      <h1>{title}</h1>
      <div className="lead">
        <HTMLContent content={content} />
      </div>
    </div>
  )
}
